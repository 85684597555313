import * as React from 'react';
import {Shared} from 'modules';
import ROUTES from 'platform/constants/routes';
import {byRoute} from 'platform/decorators/routes';
import {IProductListResponseModel} from 'platform/api/product/models/response';
import ProductController from 'platform/api/product';
import PageLoader from 'components/page-loader';
import {buildFilters} from './services/helper';
import Filter from './components/filter';
import Connection from 'platform/services/connection';
import SortBox from './components/sort-box';
import Pagination from 'components/pagination';
import EmptyViewSvg from 'assets/images/emptyView.svg';
import HelperComponent from 'platform/classes/helper-component';
import Settings from 'platform/services/settings';
import './style.scss';
import Storage from "../../../../../platform/services/storage";
import {Link} from "react-router-dom";
import {ICategoryTreeResponseModel} from "../../../../../platform/api/category/models/response";
import {IProductFilterRequestModel} from "../../../../../platform/api/product/models/request";
import GtagData from "../../../../../platform/services/gtagData";

const pageChangeListener = 'productlistpage';

interface IProps {
    onChange(): void;
};

interface IState {
    loading: boolean;
    sortShow: boolean;
    total: null;
    preferredProductId: number | null,
    data?: IProductListResponseModel[];
    specialProducts?: IProductListResponseModel[];
    categoryTreeData: ICategoryTreeResponseModel[];
    body: IProductFilterRequestModel;
    categoryTitle: string,
    itemsPerPage: number
};

@byRoute([ROUTES.PRODUCTS.MAIN])
class List extends HelperComponent<IProps, IState> {

    public state: IState = {
        loading: false,
        total: null,
        preferredProductId: null,
        categoryTreeData: [],
        sortShow: false,
        body: {
            brandIds: [],
            producerIds: [],
            categoryIds: [],
            activeIngredientIds: [],
        },
        categoryTitle:'',
        itemsPerPage: 15
    };

    private filterChange = () => {
        this.safeSetState({data: []});
        Connection.AbortAll();
        window.dispatchEvent(new CustomEvent(pageChangeListener, {detail: 1}));
    }

    private toggleFilter = (e) => {
        this.safeSetState({ sortShow: e })
    }

    private fetchData = async (pageNumber: number,itemsPerPage:number): Promise<any> => {
        if (window.innerWidth < 901) {
            const headerElement = document.getElementById('header');
            if (headerElement) {
                headerElement.style.display = 'none';
            }
        }
        this.safeSetState({loading: true});
        const body = {
            ...buildFilters(),
            pageNumber,
            pageSize: itemsPerPage,
        };
        const query = new URLSearchParams(window.location.search);

        ////////////////////////
        const categoryId = query.get('categoryIds');
        this.getCategoryTitle(Number(categoryId))
        //////////////

        // let categoryId = query.get('categoryIds');
        // if (categoryId && categoryId.indexOf(',') > -1) {
        //     const correctId = categoryId?.split(',')
        //     if (correctId?.length === 2) {
        //         categoryId = correctId[correctId.length-1]
        //     } else {
        //         categoryId = correctId[0]
        //     }
        // }
        // if (categoryId){
        //     const categoryTreeResult = await CategoryController.GetCategoryTree(categoryId);
        //     if (categoryTreeResult && categoryTreeResult.success){
        //         this.safeSetState({
        //             categoryTreeData: categoryTreeResult.data.categoryTreeModels,
        //             categoryTitle:categoryTreeResult.data.categoryTreeModels[categoryTreeResult.data.categoryTreeModels.length-1].name});
        //     }
        // }
        ////////////////////////
        const isSpecialOffers = query.get('specialOffers');
        const preferredProductId = query.get('preferredProductId');

        if(isSpecialOffers){
           const result = await ProductController.GetSpecialProducts();
            if(!result.aborted && result.data){
                this.safeSetState({
                    data: result.data,
                    total: result.data?.length,
                    preferredProductId,
                    loading: false
                }, () => window.scrollTo(0, 0))
                const {categoryTitle} = this.state;
                GtagData(result.data,"view_item_list",categoryTitle,true)
            }
        }else{
           const result = await ProductController.GetList(body);
            if(!result.aborted && result.data){
                this.safeSetState({
                    data: result.data.list,
                    total:  result.data.totalCount,
                    preferredProductId,
                    loading: false
                }, () => window.scrollTo(0, 0))
                const {categoryTitle} = this.state;
                GtagData(result.data,"view_item_list",categoryTitle,true)
            }
            return result.data;
        }
    }

    private goToProductsPage = () => {
        window.location.search='?sortBy=1&page=1'
    }

    private  clearChildCategoryFilter = (id) =>{
        const query = new URLSearchParams(window.location.search);
        query.set('categoryIds', `${id}`);
        window.routerHistory.replace(`${ROUTES.PRODUCTS.MAIN}?${query.toString()}`);
        window.location.reload()
    }
    ////////////////////////
    private getCategoryTitle = (categoryId: number) => {
       const result = Storage.categories.find(item => {
            return item.id === categoryId;
        });
       this.safeSetState({categoryTitle:result?.name || null})
        }
   public handleItemsPerPageChange = (itemsPerPage: number) => {
        this.setState({ itemsPerPage });
    };
    ////////////////////////
    public render() {
        const query = new URLSearchParams(window.location.search);
        const categoryId = query.get('categoryIds');
        const {data, total, loading, preferredProductId, sortShow,categoryTreeData,categoryTitle,itemsPerPage } = this.state;
        return (
            <div>
                <div>
                    {/*////////////////////////*/}
                    {/*Categoryyy*/}
                    {/*<ul className="Breadcrumbs">*/}
                    {/*    <li className='fromRoutes'><Link to={ROUTES.MN}> {Settings.translations.home}</Link></li>*/}
                    {/*    <li className='fromRoutes' onClick={this.goToProductsPage}> {Settings.translations.products}</li>*/}
                    {/*    {categoryTreeData && categoryId && categoryTreeData.map((item, index) =>*/}
                    {/*        <li className={index !== categoryTreeData.length-1 ? 'fromRoutes' : 'activeRoute'}  key={item.id} >*/}
                    {/*            {(index !== categoryTreeData.length-1) ?*/}
                    {/*                <Link onClick={()=>this.clearChildCategoryFilter(item.id)} to={window.location.pathname+`?categoryIds=${item.id}&sortBy=1&page=1`}>{item.name}</Link> :  item.name*/}
                    {/*            }*/}
                    {/*        </li>)}*/}
                    {/*</ul>*/}
                    {/*////////////////////////*/}
                    <ul className="Breadcrumbs">
                        <li className='fromRoutes'><Link to={ROUTES.MN}> {Settings.translations.home}</Link></li>
                        <li className='fromRoutes'><Link
                            to={ROUTES.PRODUCTS.MAIN}> {Settings.translations.products}</Link></li>
                        {categoryId ? <li className='activeRoute'>{categoryTitle}</li> : ''}
                    </ul>
                </div>
                <div className="G-page G-page-title-category">
                    {/*: window.location.href.indexOf("sortBy=")!==-1? <h3 className="G-page-title-left">{Settings.translations.special_offers}</h3>*/}
                    {categoryId ? <h3 className="G-page-title-left G-h3-calc">{categoryTitle}</h3> : null}
                </div>
                {<section className="G-page P-products-list-page">
                    <div className="mobileFilter">
                        <SortBox onChange={this.filterChange}/>
                        <Filter onChange={this.filterChange}/>
                    </div>
                    <Filter onChange={this.filterChange}/>
                    <div className="P-list-wrapper">
                        {data && !!total && <>
                            <SortBox onChange={this.filterChange}/>
                            {data.map(item => <Shared.Products.ListItem key={item.id}
                                                                        specialProductId={preferredProductId}
                                                                        categoryName={categoryTitle}
                                                                        data={item}/>)}

                        </>}
                        {total === 0 && <div className='P-no-data'>
                            <img src={EmptyViewSvg} alt="empty"/>
                            <p className='P-desc'>{Settings.translations.no_search_result}</p>
                        </div>}
                        <Pagination<IProductListResponseModel> classNameList="P-product-page"
                                                               pageChangeListener={pageChangeListener}
                                                               fetchData={this.fetchData} showItemsPerPage={true} onItemsPerPageChange={this.handleItemsPerPageChange}/>
                    </div>
                    {loading && <PageLoader/>}
                </section>
                    // :
                    //     window.location.href.indexOf("sortBy=")===-1?<section className="G-page P-products-list-page">
                    //       <Filter onChange={this.filterChange}/>
                    //       <div className="P-list-wrapper">
                    //         {data && !!total && <>
                    //           <SortBox onChange={this.filterChange}/>
                    //           {data.map(item => <Shared.Products.ListItem key={item.id} specialProductId={preferredProductId}
                    //                                                       data={item}/>)}
                    //         </>}
                    //         {total === 0 && <div className='P-no-data'>
                    //           <img src={EmptyViewSvg} alt="empty"/>
                    //           <p className='P-desc'>{Settings.translations.no_search_result}</p>
                    //         </div>}
                    //         <Pagination<IProductListResponseModel> classNameList="P-product-page"
                    //                                                pageChangeListener={pageChangeListener} fetchData={this.fetchData}/>
                    //       </div>
                    //       {loading && <PageLoader/>}
                    //     </section>:<section className="G-page P-products-list-page">
                    //   <Filter onChange={this.filterChange}/>
                    //   <div className="P-list-wrapper">
                    //     {specialProducts && <>
                    //       <SortBox onChange={this.filterChange}/>
                    //       {specialProducts.map(item => <Shared.Products.ListItem key={item.id} specialProductId={preferredProductId}
                    //                                                   data={item}/>)}
                    //     </>}
                    //     {total === 0 && <div className='P-no-data'>
                    //       <img src={EmptyViewSvg} alt="empty"/>
                    //       <p className='P-desc'>{Settings.translations.no_search_result}</p>
                    //     </div>}
                    //   </div>
                    //   {loading && <PageLoader/>}
                    // </section>
                }
            </div>
        );
    }
};
export default List;
